<template>
  <v-card class="d-flex align-center bg py-0 ma-0" :height="getFooterH">
    <v-row class="d-flex justify-center py-0">
      <v-col cols="3" class="d-flex align-center py-0">
        <v-img
          height="18px"
          width="100"
          contain
          :src="`${getPath}/Logo.png`"
          @dblclick="this.$router.push('/platezh')"
          class="align-center"
        ></v-img>
      </v-col>
      <v-col class="primary text-center white--text" cols="9">
        2020 - {{ new Date().getFullYear() }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getPath', 'getFooterH']),
  },
};
</script>

<style lang="sass" scoped>
.bg
	background-color: darkblue
	color: #fff
</style>
