<template>
  <!-- <v-app class="d-flex justify-center fill-height"> -->
  <v-app class="d-flex justify-center">
    <MyHeader />
    <v-main>
      <router-view />
    </v-main>
    <MyFooter />
  </v-app>
</template>

<script>
import MyHeader from "./components/MyHeader.vue";
import MyFooter from "./components/MyFooter.vue";
// import MainPage from './components/MainPage.vue'
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    MyHeader,
    MyFooter,
  },
  methods: {
    onGetAllJsons() {
      // this.updateRout()
      this.fetchAll();
    },
    ...mapActions(["fetchAll", "updatePH", "updateMainPH"]),
  },
  beforeMount() {
    this.onGetAllJsons();
    // this.onGetRouter()
  },
  mounted() {
    this.updateMainPH(
      window.innerHeight - this.getHeaderHVal - this.getFooterHVal
    );
    this.updatePH(this.getMainPHVal - window.innerHeight * 0.09);
  },
  computed: {
    ...mapGetters([
      "getMainPHVal",
      "getFooterHVal",
      "getHeaderHVal",
      "getMainPH",
    ]),
  },
};
</script>

<style lang="sass">
*
	margin: 0 !important
	padding: 0
	-webkit-box-sizing: border-box
	box-sizing: border-box
	font-size: 1 rem

#app
	font-family: Avenir, Helvetica, Arial, sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	// overflow: hidden
	text-align: center
	color: #2c3e50

html
	overflow-y: auto !important
	font-size: 150%
	@media only screen and (min-width: 600px)
		font-size: 94%

	@media only screen and (min-width: 1000px)
		font-size: 98%

	@media only screen and (min-width: 1200px)
		font-size: 100%

.v-container
	max-width: 100vw !important
	height: calc(100vh - 133px)
	height: 100%
	padding: 0

// .v-application--wrap
//   min-height: 78vh

.avatar
	height: 50vh
	width: 20vw
	object-fit: cover
	object-align: center

.imgintext
	display: flex
	justify-content: space-around
	margin-bottom: 20px
	flex-wrap: wrap
	gap: 10px

.v-main
	// max-height: calc(100vh - 130px)
	// max-height: 80vh
	align-items: center
	// overflow-y: auto

.row
	justify-content: space-around

.buttons
	display: flex
	justify-content: space-between
	margin-bottom: 20px !important

.v-card
	overflow: hidden
	transition: .2s ease-in-out

.backgrounding
	background-color: #ddd

.bg
	background-color: #ddd
	border: 1px solid #ddd

// .v-image
// 	transition: .2s ease-in-out
// 	&:hover
// 		transform: scale(1.05)

.full-picture
	position: fixed !important
	width: 100vw
	height: 100vh
	left: 0
	top: 0
	z-index: 9999 !important
	background-color: #fff

.fill-height
	height: 80vh
	// max-height: 100vh

.v-img__img--preload
	filter: none !important
// .picture
// 	position: relative !important
// 	top: 0
// 	left: 0
// 	z-index: -1

.text-h5
	background-color: rgba(#FFF, 0.4)
</style>
