import { createStore } from 'vuex';
import sitesJson from './sites.json'
import photosJson from './photos.json'
import hobbyJson from './hobby.json'
import handmadeJson from './handmade.json'
import dysnaiJson from './dysnai2005.json';
import aboutmeJson from './aboutme.json';
// import './potreblenie.js'
import { potreblenie } from './potreblenie';
import { tarifModule } from './modules/tarifModule';
import { potreblenieModule } from './modules/potreblenieModule';
import axios from 'axios';
import store from '@/store';

export default createStore({
  state: {
    view: 'links', //режим отображения
    photoCurrentPage: [],
    cols: 'auto',
    mySitesJson: [],
    myPhotosJson: [],
    myHobbyJson: [],
    myHandmadeJson: [],
    myDysnaiJson: dysnaiJson,
    album: [], //текщий альбом (Фото, Хобби, Сайты)
    albumName: 'Фотографии',
    fz: 16,
    lh: 1.5,
    ph: 0,
    mainPH: 0,
    footerH: 48,
    headerH: 55,
    fontSize: {},
    // path: 'http://localhost:8080/assets/store/',
    path: 'https://rasvv.ru/assets/store/',
    imgpath: 'https://rasvv.ru/assets/img',
    jsonFile: '',
    dText1:
      'Во-первых, все началось с того, что за два месяца до фестиваля мне позвонили и сказали, что я должен буду поехать на Диснай. До этого у	меня не было загранпаспорта, поэтому резко кинулся сдавать документы на получение данного документа. Про то, как получался этот паспорт – отдельная история, полная негодования и матерных выражений.',
  },
  mutations: {
    setSitesJson(state, payload) {
      state.mySitesJson = payload;
    },
    setPhotosJson(state, payload) {
      state.myPhotosJson = payload;
    },
    setHobbyJson(state, payload) {
      state.myHobbyJson = payload;
    },
    setHandmadeJson(state, payload) {
      state.myHandmadeJson = payload;
    },
    // setSitesJson (state, payload) {
    //   state.mySitesJson = payload
    // },

    setView(state, payload) {
      state.view = payload;
    },
    setPhotoCurrentPage(state, payload) {
      state.photoCurrentPage = payload;
    },
    setAlbum(state, payload) {
      console.log(payload);
      state.album = payload;
    },
    setAlbumName(state, payload) {
      // console.log(payload);
      state.albumName = payload;
    },
    setFontSize(state, payload) {
      state.fontSize = payload;
    },
    setFZ(state, payload) {
      state.fz = payload;
    },
    setLH(state, payload) {
      state.lh = payload;
    },
    setPH(state, payload) {
      state.ph = payload;
    },
    setMainPH(state, payload) {
      state.mainPH = payload;
    },
    setJsonFile(state, payload) {
      state.jsonFile = payload;
    },
  },
  actions: {
    async fetchAll({ state, commit }) {
      try {
        let response = await axios.get(`${store.state.path}sites.json`);
        commit('setSitesJson', response.data);
        console.log(response.data);

        response = await axios.get(`${store.state.path}photos.json`);
        commit('setPhotosJson', response.data);
        commit('setAlbum', response.data);

        response = await axios.get(`${store.state.path}hobby.json`);
        commit('setHobbyJson', response.data);

        response = await axios.get(`${store.state.path}handmade.json`);
        commit('setHandmadeJson', response.data);
      } catch (e) {
        console.log(e);
      }
    },
    updatePhotoCurrentPage({ commit }, page) {
      return commit('setPhotoCurrentPage', page);
    },
    updateView({ commit }, view) {
      console.log(view);
      return commit('setView', view);
    },
    updateAlbum({ commit }, album) {
      console.log('updateAlbum ' + album);
      return commit('setAlbum', album);
    },
    updateAlbumName({ commit }, albumName) {
      return commit('setAlbumName', albumName);
    },
    updateFontSize({ commit }, fontSize) {
      return commit('setFontSize', fontSize);
    },
    updateFZ({ commit }, fz) {
      return commit('setFZ', fz);
    },
    updateLH({ commit }, lh) {
      return commit('setLH', lh);
    },
    updatePH({ commit }, ph) {
      console.log('ph= ' + ph);
      return commit('setPH', ph);
    },
    updateMainPH({ commit }, mainPH) {
      console.log('MainPH= ' + mainPH);
      return commit('setMainPH', mainPH);
    },
  },
  getters: {
    getView: (state) => state.view,
    getPhotoCurrentPage: (state) => state.photoCurrentPage,
    getAlbum: (state) => state.album,
    getAlbumName: (state) => state.albumName,
    getPhotosLinks: (state) => state.myPhotosJson,
    getHobbyLinks: (state) => state.myHobbyJson,
    getHandmadeLinks: (state) => state.myHandmadeJson,
    getDysnaiLinks: (state) => state.myDysnaiJson,
    getSitesLinks: (state) => state.mySitesJson,
    getFontSize: (state) => {
      (state.fontSize.fontSize = state.fz + 'px'),
        (state.fontSize.lineHeight = state.lh + 'em');
      return state.fontSize;
      // console.log(state.fontSize)
    },
    getFZ: (state) => state.fz,
    getLH: (state) => state.lh,
    getPH: (state) => state.ph + 'px',
    getPHVal: (state) => state.ph,

    getMainPH: (state) => state.mainPH + 'px',
    getMainPHVal: (state) => state.mainPH,

    getHeaderH: (state) => state.headerH + 'px',
    getHeaderHVal: (state) => state.headerH,

    getFooterH: (state) => state.footerH + 'px',
    getFooterHVal: (state) => state.footerH,

    getPath: (state) => state.imgpath,
    getDText1: (state) => state.dText1,
  },
  modules: {
    tarifModule: tarifModule,
    potreblenieModule: potreblenieModule,
  },
});
